<template>
  <header id="header" class="fixed-top new-header">
    <div class="container d-flex align-items-center justify-content-between">
      <!-- Logo -->
      <a href="/" class="logo">
        <img src="@/assets/img/logo4.png" alt="Logo" class="img-fluid" />
      </a>

      <!-- Navbar -->
      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <router-link :to="{ name: 'Home', hash: '#hero' }" class="nav-link scrollto" active-class="active">
              <i class="bi bi-house-door"></i> Home
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Home', hash: '#about' }" class="nav-link scrollto" active-class="active">
              <i class="bi bi-info-circle"></i> About
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Home', hash: '#services' }" class="nav-link scrollto" active-class="active">
              <i class="bi bi-gear"></i> Services
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Projects' }" class="nav-link" active-class="active">
              <i class="bi bi-briefcase"></i> Projects
            </router-link>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "SiteHeader",
};
</script>

<style scoped>
/* Fixed Header */
.new-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(45deg, #1e3c72, #2a5298);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Keeps the header above other content */
  transition: all 0.3s ease-in-out;
  padding: 15px 0;
  padding-top: 20px;
  /* Space for logo */
}

/* Logo Style */
.logo img {
  max-height: 55px;
  transition: transform 0.3s ease;
}

.logo img:hover {
  transform: scale(1.1) rotate(5deg);
}

/* Navbar Style */
.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 25px;
}

.navbar ul li {
  position: relative;
}

/* Navigation Link Style */
.navbar ul li .nav-link {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 12px 18px;
  border-radius: 30px;
  display: inline-block;
  transition: color 0.4s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.navbar ul li .nav-link i {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

/* Hover Effects */
.navbar ul li .nav-link:hover {
  color: #fff;
  background-color: #f39c12;
  transform: translateY(-4px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.navbar ul li .nav-link i:hover {
  transform: rotate(15deg);
}

/* Active Link Styling */
.navbar ul li .nav-link.active {
  color: #fff;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* Mobile Navigation Toggle */
.mobile-nav-toggle {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: none;
  transition: transform 0.3s ease-in-out;
}

.mobile-nav-toggle:hover {
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
    flex-direction: column;
    gap: 15px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }

  .navbar ul.show {
    display: flex;
  }

  .navbar ul li .nav-link {
    color: #333;
    font-size: 18px;
    padding: 15px;
  }

  .navbar ul li .nav-link:hover {
    color: #f39c12;
  }
}
</style>
