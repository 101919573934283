<template>
  <div id="app">
    <MainHero />
    <main id="main">
      <OurClients />
      <AboutUs />
      <SkillSets />
      <OurServices />
      <OurCta />
    </main>
  </div>
</template>

<script>
import MainHero from "./MainHero.vue";
import OurClients from "./OurClients.vue";
import AboutUs from "./AboutUs.vue";
import SkillSets from "./SkillSets.vue";
import OurServices from "./OurServices.vue";
import OurCta from "./OurCta.vue";

export default {
  components: {
    MainHero,
    OurClients,
    AboutUs,
    SkillSets,
    OurServices,
    OurCta,
  },
};
</script>

<style>
/* Add any global styles here */
</style>
