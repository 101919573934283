import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/components/HomeView.vue";
import ProjectList from "@/components/Projects/ProjectList.vue";
import ProjectDetails from "@/components/Projects/ProjectDetail.vue";
import ComingSoon from "@/components/ComingSoon/ComingSoon.vue";

const routes = [
  { path: "/", name: "Home", component: HomeView },
  { path: "/projects", name: "Projects", component: ProjectList },
  {
    path: "/project-details",
    name: "ProjectDetails",
    component: ProjectDetails,
    props: (route) => ({ duration: route.query.duration }),
  },
  { path: "/coming-soon", name: "ComingSoon", component: ComingSoon },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // When using the browser's back/forward buttons
    } else if (to.hash) {
      return { el: to.hash, behavior: "smooth" }; // Scroll to the hash
    } else {
      return { top: 0 }; // Default scroll to top for new pages
    }
  },
});

export default router;
