<template>
  <section id="about" class="about backgroundImage2">
    <div class="container aboutBg" data-aos="fade-up">
      <div class="section-title">
        <h2>About</h2>
      </div>
      <div class="row content">
        <div class="col-md-6">
          <p class="text-primary font-weight-bold">👉 What is the work?</p>
          <ul>
            <li><i class="ri-check-double-line"></i> It is a home-based job.</li>
            <li>
              <i class="ri-check-double-line"></i> You will receive all writing and
              stationery materials with an instruction manual at your home.
            </li>
            <li>
              <i class="ri-check-double-line"></i> You just have to copy down text as per
              instruction.
            </li>
          </ul>
        </div>
        <div class="col-md-6 pt-4 pt-lg-0">
          <p class="text-primary font-weight-bold">
            The Harmonious Keynotes of Accomplished Writers
          </p>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li><i class="ri-check-double-line"></i> Creativity</li>
                <li><i class="ri-check-double-line"></i> Discipline.</li>
                <li><i class="ri-check-double-line"></i> Passion.</li>
                <li><i class="ri-check-double-line"></i> Observation.</li>
                <li><i class="ri-check-double-line"></i> Adaptability.</li>
                <li><i class="ri-check-double-line"></i> Curiosity.</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li><i class="ri-check-double-line"></i> Communication Skills.</li>
                <li><i class="ri-check-double-line"></i> Research Abilities.</li>
                <li><i class="ri-check-double-line"></i> Open-mindedness.</li>
                <li><i class="ri-check-double-line"></i> Networking.</li>
                <li><i class="ri-check-double-line"></i> Organization.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
