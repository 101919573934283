<template>
  <div class="row">
    <div v-for="(project, index) in projects" :key="index" class="col-lg-6" data-aos="fade-up"
      :data-aos-delay="(index + 1) * 50">
      <div :class="['card', 'box', project.class]">
        <!-- Marquee Section -->
        <div class="marqueeSection">
          <marquee class="marqueeClass">
            <h4 class="blink blinkText">
              <sup>Payout</sup>
              <i class="rupee-symbol">{{ project.payout }}</i>
            </h4>
          </marquee>
        </div>
        <ul>
          <li>
            <i class="bx bx-check successXicon"></i>
            Security: <span class="rupee-symbol"></span>{{ project.security }}
            (Refundable)
          </li>
          <li>
            <i class="bx bx-check successXicon"></i>
            Time(Duration): {{ project.duration }} Days
          </li>
          <li v-if="project.note">
            <i v-if="project.noteIcon" class="bx bx-check successXicon" style="color: green"></i>
            <i v-else class="bx bx-user-x dangerXicon" style="color: red"></i>
            <span v-if="!project.noteIcon" style="color:red">Note:</span> {{ project.note }}
          </li>


        </ul>
        <router-link :to="{ name: 'ProjectDetails', query: { duration: project.duration + 'days' } }" class="buy-btn">
          Get Started
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-lg-6 {
  width: 48%;
  /* Ensure two boxes fit per row */
  margin-bottom: 20px;
}
</style>