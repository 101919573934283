<template>
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Services</h2>
        <p>We offer a variety of services to meet the diverse needs of our clients.</p>
      </div>
      <div class="row">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-box">
            <div class="icon"><i :class="service.icon"></i></div>
            <h4>
              <a :href="service.link">{{ service.title }}</a>
            </h4>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      services: [
        {
          title: "Content Writing",
          description: "Professional content creation for websites and blogs.",
          icon: "bx bx-pencil",
          link: "#",
        },
        {
          title: "Editing Services",
          description: "Comprehensive editing for manuscripts and documents.",
          icon: "bx bx-edit",
          link: "#",
        },
        {
          title: "Skill Training",
          description: "Workshops to improve your writing and organizational skills.",
          icon: "bx bx-brain",
          link: "#",
        },
      ],
    };
  },
};
</script>
