<template>
  <div class="coming-soon-page">
    <section class="coming-soon-content">
      <div class="container text-center">
        <h1 class="coming-soon-title">Coming Soon!</h1>
        <p class="coming-soon-description">
          We're working hard to bring you something amazing! Stay tuned for updates.
        </p>

        <!-- Countdown Timer -->
        <div v-if="countdown">
          <h2 class="countdown-title">Launching in:</h2>
          <div class="countdown-timer">
            <span>{{ days }}</span> Days
            <span>{{ hours }}</span> Hours
            <span>{{ minutes }}</span> Minutes
            <span>{{ seconds }}</span> Seconds
          </div>
        </div>

        <!-- Newsletter Subscription -->
        <div class="newsletter">
          <h3>Stay Updated</h3>
          <input v-model="email" type="email" placeholder="Enter your email" class="email-input" />
          <button @click="subscribe" class="subscribe-button">Subscribe</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      countdown: true,
      targetDate: new Date("2024-12-31T00:00:00"), // Set the target date for the countdown
      timer: null,
      timeLeft: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    // Calculate the remaining time
    days() {
      return this.timeLeft.days;
    },
    hours() {
      return this.timeLeft.hours;
    },
    minutes() {
      return this.timeLeft.minutes;
    },
    seconds() {
      return this.timeLeft.seconds;
    },
  },
  methods: {
    subscribe() {
      if (this.email) {
        alert(`Thank you for subscribing! We'll keep you updated.`);
        this.email = ""; // Clear the email input after submission
      } else {
        alert("Please enter a valid email.");
      }
    },
    updateCountdown() {
      const now = new Date();
      const timeDiff = this.targetDate - now;

      if (timeDiff <= 0) {
        this.countdown = false;
        clearInterval(this.timer);
        return;
      }

      this.timeLeft.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      this.timeLeft.hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.timeLeft.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      this.timeLeft.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    },
  },
  mounted() {
    // Start the countdown timer
    this.updateCountdown(); // Initial update
    this.timer = setInterval(this.updateCountdown, 1000);
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style scoped>
.coming-soon-page {
  background: url('@/assets/img/comingsoon/comingsoon8.webp') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.coming-soon-content {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
}

.coming-soon-title {
  font-size: 3em;
  margin-bottom: 20px;
}

.coming-soon-description {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.countdown-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.countdown-timer {
  font-size: 1.8em;
  margin-bottom: 30px;
}

.countdown-timer span {
  margin: 0 10px;
}

.newsletter {
  margin-top: 30px;
}

.email-input {
  padding: 10px;
  font-size: 1.2em;
  width: 70%;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
}

.subscribe-button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #218838;
}
</style>
