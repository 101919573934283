<template>
  <section id="skills" class="skills section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Skills</h2>
        <p>
          Our skilled team ensures the quality of the work. Here are some expertise areas
          we focus on:
        </p>
      </div>
      <div class="row skills-content">
        <div class="col-lg-6">
          <div v-for="(skill, index) in skills" :key="index" class="progress">
            <span class="skill"
              >{{ skill.name }} <i>{{ skill.percentage }}%</i></span
            >
            <div class="progress-bar-wrap">
              <div class="progress-bar" :style="{ width: skill.percentage + '%' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SkillSets",
  data() {
    return {
      skills: [
        { name: "Creativity", percentage: 90 },
        { name: "Time Management", percentage: 85 },
        { name: "Attention to Detail", percentage: 80 },
        { name: "Communication", percentage: 75 },
      ],
    };
  },
};
</script>
