<template>
  <div id="app">
    <SiteHeader />
    <router-view />
    <MainFooter />
  </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import MainFooter from "./components/MainFooter.vue";

export default {
  components: {
    SiteHeader,
    MainFooter,
  },
};
</script>

<style>
/* Add any global styles here */
</style>
