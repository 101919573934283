<template>
  <main id="main" style="margin-top:4.7%; display: grid;">
    <section id="pricing" class="pricing" style="background:#1d5dde5c;">
      <div class="container" data-aos="fade-up">
        <div class="project-title">
          <h2><span id="titlePage">Projects</span></h2>
        </div>

        <!-- Tab Navigation -->
        <ul class="nav nav-pills nav-fill" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="{ active: selectedTab === 'english' }" @click="changeTab('english')">
              English
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="{ active: selectedTab === 'hindi' }" @click="changeTab('hindi')">
              Hindi
            </button>
          </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content">
          <ProjectCard :projects="currentProjects" :key="selectedTab" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ProjectCard from "./ProjectCard.vue";

export default {
  components: { ProjectCard },
  data() {
    return {
      selectedTab: "english", // Default selected tab
      englishProjects: [
        { class: "boxOne", payout: "5500", security: "850", duration: 5, note: "Pickup Service not available", noteIcon: false },
        { class: "boxTwo", payout: "9000", security: "1300", duration: 8, note: "Pickup Service not available", noteIcon: false },
        { class: "boxThree", payout: "11500", security: "1800", duration: 12, note: "Both Side Courier service is available", noteIcon: true },
        { class: "boxFour", payout: "22500", security: "2500", duration: 18, note: "Both Side Courier service is available", noteIcon: true },
      ],
      hindiProjects: [
        { class: "boxFour", payout: "12000", security: "2000", duration: 10, note: "Both Side Courier service is available", noteIcon: true },
      ],
    };
  },
  computed: {
    currentProjects() {
      return this.selectedTab === "english" ? this.englishProjects : this.hindiProjects;
    },
  },
  methods: {
    changeTab(tab) {
      console.log("Switching to tab:", tab); // Debugging
      this.selectedTab = tab;
    },
  },
};
</script>
