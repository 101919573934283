<template>
  <section id="clients" class="clients section-bg">
    <div class="container" style="padding: 4em">
      <p class="headText">Available Projects</p>
      <div class="row" data-aos="zoom-in">
        <div v-for="(project, index) in projects" :key="index"
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
          <span class="textFive">
            <router-link :to="{ name: 'ProjectDetails', query: { duration: project.days } }" class="buy-btn">
              {{ project.days }}
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurClients",
  data() {
    return {
      projects: [
        { days: "5days" },
        { days: "8days" },
        { days: "10days" },
        { days: "12days" },
        { days: "18days" },
      ],
    };
  },
};
</script>
