<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>All India Book Publishers</h3>
            <p>
              Gayatri Nagar <br />
              Badgaon Udaipur 313011<br />
              <strong>Phone:</strong> +91 6375371033<br />
              <strong>Email:</strong> allindiabookpublishers@gmail.com<br />
            </p>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>

              <li><i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'Home', hash: '#hero' }" class="nav-link scrollto">
                  Home
                </router-link>
              </li>
              <li><i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'Home', hash: '#about' }" class="nav-link scrollto">
                  About
                </router-link>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'Home', hash: '#services' }" class="nav-link scrollto">
                  Services
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'ComingSoon' }" class="nav-link scrollto">
                  Content Writing
                </router-link>
              </li>
              <li><i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'ComingSoon' }" class="nav-link scrollto">
                  Editing
                </router-link>
              </li>
              <li><i class="bx bx-chevron-right"></i>
                <router-link :to="{ name: 'ComingSoon' }" class="nav-link scrollto">
                  Skill Training
                </router-link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <div class="container d-md-flex py-4" style="color:whitesmoke">
      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; 2024 <strong><span>all india book publishers</span></strong> All Rights Reserved.
        </div>
        <div class="credits">Designed by <a href="#">All india book publishers</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>
