<template>
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">
      <div class="text-center">
        <h3>Call to Action</h3>
        <p>
          Ready to start your journey with us? Get in touch today to explore our services
          and opportunities.
        </p>
        <a class="cta-btn align-middle" href="tel:6375371033">Call Now</a>
        <a
          class="cta-btn"
          href="https://api.whatsapp.com/send/?phone=6375371033&text&type=phone_number&app_absent=0"
          >Whatsapp now
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurCta",
};
</script>
