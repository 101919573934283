<template>
  <main id="main">
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <h2>Project Details</h2>
      </div>
    </section>

    <section id="portfolio-details" class="portfolio-details">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-10">
            <div class="portfolio-info">
              <h3>Project Information</h3>
              <div class="row">
                <div class="col-md-6">
                  <ul>
                    <li><strong>Payout</strong>: {{ project.payout }}</li>
                    <li><strong>Book Security</strong>: {{ project.bookSecurity }}</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li><strong>Time/Duration</strong>: {{ project.timeDuration }}</li>
                    <li><strong>{{ project.serviceTitle }}</strong>: {{ project.serviceValue }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="portfolio-description">
              <h2>Description</h2>
              <div v-html="projectDescription"></div>
            </div>

            <div class="portfolio-description">
              <h2>Joining Process</h2>
              <ul>
                <li>Any ID Proof.</li>
                <li>Current Address With Pin-code.</li>
                <li>Book Security Deposit.</li>
              </ul>
            </div>

            <div class="portfolio-description">
              <h2>Instructions</h2>
              <ul>
                <li>Provide All India Courier Service.</li>
                <li>Courier Time is not included in Writing.</li>
                <li>Security amount is refundable with salary payout after completing book according to the rules.</li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="portfolio-info">
              <h3>Project List</h3>
              <ul>
                <li class="cursorX" v-for="(projectDetail, slug) in projects" :key="slug">
                  <strong @click="changeProjectDetails(slug)">{{ projectDetail.timeDuration }}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      project: {},
      projects: {
        "5days": {
          payout: "5500",
          bookSecurity: "850",
          timeDuration: "5 Days",
          serviceTitle: "Pick Up Service",
          serviceValue: "No",
          pageCount: "50",
          pageBothSide: "100",
          projectLanguage: "English",
        },
        "8days": {
          payout: "9000",
          bookSecurity: "1300",
          timeDuration: "8 Days",
          serviceTitle: "Pick Up Service",
          serviceValue: "No",
          pageCount: "85",
          pageBothSide: "170",
          projectLanguage: "English",
        },
        "10days": {
          payout: "12000",
          bookSecurity: "2000",
          timeDuration: "10 Days",
          serviceTitle: "Both Side Courier Services is available",
          serviceValue: "Yes",
          pageCount: "160",
          pageBothSide: "320",
          projectLanguage: "Hindi",
        },
        "12days": {
          payout: "11500",
          bookSecurity: "1800",
          timeDuration: "12 Days",
          serviceTitle: "Both Side Courier Services is available",
          serviceValue: "Yes",
          pageCount: "140",
          pageBothSide: "280",
          projectLanguage: "English",
        },
        "18days": {
          payout: "22500",
          bookSecurity: "2500",
          timeDuration: "18 Days",
          serviceTitle: "Both Side Courier Services is available",
          serviceValue: "Yes",
          pageCount: "170",
          pageBothSide: "340",
          projectLanguage: "English",
        },
      },
      projectDescription: "",
    };
  },
  methods: {
    changeProjectDetails(slug) {
      const selectedProject = this.projects[slug];
      this.project = selectedProject;

      // Update the project description dynamically
      this.projectDescription = `
        <p>
          Great opportunity for home-based work (part-time or full-time)!<br>
          <li>This project is available in <b>${selectedProject.projectLanguage}</b>.</li>
          <li>This project involves accurately copying and re-writing text from a novel book.</li>
          <li>Copying and writing in capital letters for a <b>${selectedProject.pageCount}</b>-page book (front and back, making it a total of <b>${selectedProject.pageBothSide}</b> pages).</li>
        </p>
      `;
    },
  },
  created() {
    const slug = this.$route.query.duration || "5days"; // Default to '5days' if no slug is provided
    this.changeProjectDetails(slug);
  },
};
</script>

<style scoped>
/* Add any scoped CSS if needed */
</style>
