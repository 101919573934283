import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
// import "./netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css";

createApp(App).use(router).mount("#app");
