<template>
  <section id="hero" class="d-flex align-items-center backgroundImage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up" data-aos-delay="200">
          <h1>We are a team to give you Self Employment</h1>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a href="#about" class="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <div class="imgTagDiv">
            <img :src="require('@/assets/img/home2.jpg')" class="img-fluid animated imgTag" alt="Example Image" />

            <!-- <img src="assets/img/home2.jpg" class="img-fluid animated imgTag" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>
